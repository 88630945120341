import getCookieHeader from '~/core/generic/get-cookie-header'

export const THEME_COOKIE_NAME = 'app-theme'

export async function parseThemeCookie(
  request: Request,
): Promise<string | null> {
  const cookie = await getThemeCookie()
  return cookie.parse(getCookieHeader(request))
}

async function getThemeCookie() {
  const { createCookie } = await import('@remix-run/node')
  const secure = process.env.ENVIRONMENT === 'production'

  return createCookie(THEME_COOKIE_NAME, {
    path: '/',
    httpOnly: false,
    secure,
    sameSite: 'lax' as const,
  })
}
